import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Flex } from '../Flex';
import { Box } from '../Box';
import { Loader, CircleLoader, BounceLoader, HeartLoader, Skeleton } from '.';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "loaders"
    }}>{`Loaders`}</h1>
    <p>{`Various types of loaders`}</p>
    <h2 {...{
      "id": "loader"
    }}>{`Loader`}</h2>
    <Props of={Loader} mdxType="Props" />
    <p>{`A simple "typing" style loader... Use this for generic loading screens, where you can't use `}<a parentName="p" {...{
        "href": "#skeleton"
      }}>{`Skeleton`}</a></p>
    <Playground __position={1} __code={'<Flex alignItems=\"center\" justifyContent=\"center\">\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <Loader size={14} />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.dark\" textAlign=\"center\">\n    <Loader size={14} context=\"shadower\" />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <Loader size={14} context=\"host\" />\n  </Box>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Flex,
      Box,
      Loader,
      CircleLoader,
      BounceLoader,
      HeartLoader,
      Skeleton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="center" justifyContent="center" mdxType="Flex">
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <Loader size={14} mdxType="Loader" />
    </Box>
    <Box paddingY="large" bg="whiteout.dark" textAlign="center" mdxType="Box">
      <Loader size={14} context="shadower" mdxType="Loader" />
    </Box>
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <Loader size={14} context="host" mdxType="Loader" />
    </Box>
  </Flex>
    </Playground>
    <h2 {...{
      "id": "skeleton"
    }}>{`Skeleton`}</h2>
    <Props of={Skeleton} mdxType="Props" />
    <p>{`Fantastic to show that actual `}<strong parentName="p">{`content`}</strong>{` is being loaded. Use wherever possible.`}</p>
    <p>{`Skeleton has a`}</p>
    <ul>
      <li parentName="ul">{`Optional prop for `}<inlineCode parentName="li">{`ratio`}</inlineCode>{` that defaults to `}<inlineCode parentName="li">{`3/2`}</inlineCode></li>
      <li parentName="ul">{`Optional prop for `}<inlineCode parentName="li">{`borderRadius`}</inlineCode>{` that defaults to `}<inlineCode parentName="li">{`3px`}</inlineCode></li>
      <li parentName="ul">{`Optional prop for `}<inlineCode parentName="li">{`width`}</inlineCode>{` that defaults to `}<inlineCode parentName="li">{`100%`}</inlineCode></li>
    </ul>
    <Playground __position={3} __code={'<Flex>\n  <Box margin=\"none\" padding=\"base\" bg=\"neutral.light\" width=\"240px\">\n    <Skeleton ratio=\"3/2\" />\n    <Box marginTop=\"small\">\n      <Skeleton ratio=\"title\" borderRadius=\"0\" width=\"90%\" />\n      <Skeleton marginTop=\"small\" ratio=\"text\" width=\"75%\" />\n    </Box>\n  </Box>\n  <Box margin=\"none\" marginLeft=\"base\" padding=\"base\" width=\"240px\">\n    <Skeleton ratio=\"3/2\" />\n    <Box marginTop=\"small\">\n      <Box display=\"inline-block\" width=\"26px\">\n        <Skeleton ratio=\"1/1\" borderRadius=\"50%\" />\n      </Box>\n      <Skeleton\n        display=\"inline-block\"\n        marginLeft=\"small\"\n        ratio=\"title\"\n        borderRadius=\"3px\"\n        width=\"80%\"\n      />\n      <Skeleton marginTop=\"small\" ratio=\"text\" width=\"75%\" />\n    </Box>\n  </Box>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Flex,
      Box,
      Loader,
      CircleLoader,
      BounceLoader,
      HeartLoader,
      Skeleton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex mdxType="Flex">
    <Box margin="none" padding="base" bg="neutral.light" width="240px" mdxType="Box">
      <Skeleton ratio="3/2" mdxType="Skeleton" />
      <Box marginTop="small" mdxType="Box">
        <Skeleton ratio="title" borderRadius="0" width="90%" mdxType="Skeleton" />
        <Skeleton marginTop="small" ratio="text" width="75%" mdxType="Skeleton" />
      </Box>
    </Box>
    <Box margin="none" marginLeft="base" padding="base" width="240px" mdxType="Box">
      <Skeleton ratio="3/2" mdxType="Skeleton" />
      <Box marginTop="small" mdxType="Box">
        <Box display="inline-block" width="26px" mdxType="Box">
          <Skeleton ratio="1/1" borderRadius="50%" mdxType="Skeleton" />
        </Box>
        <Skeleton display="inline-block" marginLeft="small" ratio="title" borderRadius="3px" width="80%" mdxType="Skeleton" />
        <Skeleton marginTop="small" ratio="text" width="75%" mdxType="Skeleton" />
      </Box>
    </Box>
  </Flex>
    </Playground>
    <h2 {...{
      "id": "circleloader"
    }}>{`CircleLoader`}</h2>
    <Props of={CircleLoader} mdxType="Props" />
    <p>{`Use this only when suggesting longer load times. Processing rather than loading.`}</p>
    <Playground __position={5} __code={'<Flex alignItems=\"center\" justifyContent=\"center\">\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <CircleLoader size={20} />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.dark\" textAlign=\"center\">\n    <CircleLoader size={40} context=\"shadower\" />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <CircleLoader context=\"host\" />\n  </Box>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Flex,
      Box,
      Loader,
      CircleLoader,
      BounceLoader,
      HeartLoader,
      Skeleton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="center" justifyContent="center" mdxType="Flex">
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <CircleLoader size={20} mdxType="CircleLoader" />
    </Box>
    <Box paddingY="large" bg="whiteout.dark" textAlign="center" mdxType="Box">
      <CircleLoader size={40} context="shadower" mdxType="CircleLoader" />
    </Box>
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <CircleLoader context="host" mdxType="CircleLoader" />
    </Box>
  </Flex>
    </Playground>
    <h2 {...{
      "id": "bounceloader"
    }}>{`BounceLoader`}</h2>
    <Props of={BounceLoader} mdxType="Props" />
    <p>{`Use when you need to spice up things, default to `}<a parentName="p" {...{
        "href": "#skeleton"
      }}>{`Skeleton`}</a>{` or `}<a parentName="p" {...{
        "href": "#loader"
      }}>{`Loader`}</a>{`.`}</p>
    <Playground __position={7} __code={'<Flex alignItems=\"center\" justifyContent=\"center\">\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <BounceLoader size={20} />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.dark\" textAlign=\"center\">\n    <BounceLoader size={40} context=\"shadower\" />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <BounceLoader context=\"host\" />\n  </Box>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Flex,
      Box,
      Loader,
      CircleLoader,
      BounceLoader,
      HeartLoader,
      Skeleton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="center" justifyContent="center" mdxType="Flex">
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <BounceLoader size={20} mdxType="BounceLoader" />
    </Box>
    <Box paddingY="large" bg="whiteout.dark" textAlign="center" mdxType="Box">
      <BounceLoader size={40} context="shadower" mdxType="BounceLoader" />
    </Box>
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <BounceLoader context="host" mdxType="BounceLoader" />
    </Box>
  </Flex>
    </Playground>
    <h2 {...{
      "id": "heartloader"
    }}>{`HeartLoader`}</h2>
    <Props of={HeartLoader} mdxType="Props" />
    <p>{`Use when you need to spice up things, default to `}<a parentName="p" {...{
        "href": "#skeleton"
      }}>{`Skeleton`}</a>{` or `}<a parentName="p" {...{
        "href": "#loader"
      }}>{`Loader`}</a>{`.`}</p>
    <Playground __position={9} __code={'<Flex alignItems=\"center\" justifyContent=\"center\">\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <HeartLoader size={20} />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.dark\" textAlign=\"center\">\n    <HeartLoader size={40} context=\"shadower\" />\n  </Box>\n  <Box paddingY=\"large\" bg=\"whiteout.base\" textAlign=\"center\">\n    <HeartLoader context=\"host\" />\n  </Box>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Flex,
      Box,
      Loader,
      CircleLoader,
      BounceLoader,
      HeartLoader,
      Skeleton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Flex alignItems="center" justifyContent="center" mdxType="Flex">
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <HeartLoader size={20} mdxType="HeartLoader" />
    </Box>
    <Box paddingY="large" bg="whiteout.dark" textAlign="center" mdxType="Box">
      <HeartLoader size={40} context="shadower" mdxType="HeartLoader" />
    </Box>
    <Box paddingY="large" bg="whiteout.base" textAlign="center" mdxType="Box">
      <HeartLoader context="host" mdxType="HeartLoader" />
    </Box>
  </Flex>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      